.ModalBox {
  background: #ffffff;
  border-radius: 15px;
  padding: 70px 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
}

.ModalBox h2 {
  font-size: 18px;
}

.btns {
  margin-top: 30px;
}

.btns .cancelBtn {
  border: 0;
  border-radius: 50px;
  min-width: 100px;
  height: 30px;
  margin: 0px 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  background: #FF0000;
  color: #FFFFFF;
  text-transform: unset;
  cursor: pointer;
}

.btns .approvalBtn {
  border: 0;
  border-radius: 50px;
  min-width: 100px;
  height: 30px;
  margin: 0px 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  background: #008000;
  color: #FFFFFF;
  text-transform: unset;
  cursor: pointer;
}