#statusCard {
  background: #ffffff;
  box-shadow: 0px 4px 6px 2px rgba(225, 225, 225, 0.5);
  border-radius: 10px;
  font-family: Arial;
}

#statusCard h2 {
  margin: 0;
  font-family: Arial;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
}



.cardTitle {
  width: 100%;
  height: 50px;
  background-color: #353535;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

#statusCard h3 {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #009933;
}

#statusCard .cardContent {
  padding: 35px;
}

#statusCard .cardContent #outer-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
}

#outer-div div:nth-child(1) {
  padding: 0;
  width: 100%;
}

#outer-div div:nth-child(2) {
  padding: 0;
  width: 100%;
}

#outer-div div:nth-child(2) h3 {
  margin-top: 15px;
}

#outer-div ul {
  width: 100%;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

#statusCard .MuiTypography-root {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #767676;
  margin-left: 15px;
}

#statusCard .MuiListItemIcon-root {
  display: contents;
}