.emptyStateBox {
    align-items: center;
    position: absolute;
    top: 120px;
    padding: 0px 50px 0px 20px;
    margin: auto;
    text-align: center;
}

#emptyStateBtn {
    background: #009933;
    color: #FFFFFF;
    max-width: 200px;
    height: 40px;
    border-radius: 8px;
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    border: 0;
    text-align: center;
    margin: auto;
    cursor: pointer;
}

.emptyStateBox h1,
.emptyStateBox h3 {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 500;

}

.MuiSvgIcon-root {
    cursor: pointer;
}

.emptyStateImg {
    max-height: 300px;
}



/* Filters */

#gridDiv {
    max-width: 90%;
    margin: 0 auto 10px auto;
}

#filterGrid {
    max-width: 50%;
}

h2 {
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #009933;
    text-align: left;
    margin: 0;
}

input#txtName {
    width: 100%;
    height: 32px;
    background: #EFEFEF;
    border-radius: 4px;
    border: 0;
    color: #767676;
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding: 5px;
}

select {
    border: 0;
    width: 100%;
    height: 32px;
    background: #EFEFEF;
    border-radius: 4px;
    color: #767676;
    font-family: 'Heebo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding: 5px;
    user-select: none !important;
}

select:focus {
    outline: none !important;
}

/* Pagination */

#paginationDiv {
    max-width: 90%;
    margin: auto;
}

.paginationBtns {
    max-width: 90%;
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 10px;
    float: right;
    height: 30px;
    align-items: center;
    position: relative;

}

.paginationBtns a {
    display: block;
    width: 30px;
    height: 30px;
    border: 1px solid #009933;
    border-radius: 4px;
    text-align: center;
    line-height: 31px;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    font-family: 'Heebo', sans-serif;
    font-weight: bold;
    color: #009933;
}

.paginationBtns a:hover {
    background-color: #009933;
    color: white;
}

.paginationBtns .activePagination a {
    background-color: #009933;
    color: white !important;
    border-radius: 4px;
}

.controllerBtn {
    color: #009933;
}

.disabled {
    opacity: 0.5;
}