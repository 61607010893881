.map {
    z-index: -1 !important;
}

/* ExpandMoreIcon */
#expandIcon.MuiSvgIcon-root {
    color: white;
}

.mapCard {
    margin: 10px 10px 0px 0px;
    /* width: 30%; */

    z-index: 10 !important;
    position: absolute;
    right: 0;
    transition: .3s all ease-in-out !important;
}

.mapCard .cardContent {
    display: flex;
    justify-content: space-between;
    background: #009933;
    color: #FFFFFF !important;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    height: 60px !important;
}

.hiddenInfo {
    padding: 0px 16px !important;
}

.hiddenInfo input[type=text] {
    background: rgba(239, 239, 239, .7);
}

/* Typography of hidden info */
.hiddenInfo .MuiTypography-root {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #009933;
    text-align: start;
}

#secondTypography {
    margin-top: 5px;
}

/* Create some space in the bottom of the hidden info */
.MuiCardContent-root:last-child {
    margin-bottom: 10px;
}

#showInfo {
    padding: 0px 16px;
}

/* Typography of card */
#showInfo .MuiTypography-root {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #009933;
    text-align: start;
    margin-top: 5px;
}

#showInfo input[type=text] {
    margin-bottom: 5px;
}

#txtLocation {
    margin-bottom: 5px;
}

#txtLocation:focus {
    outline: none;
}

ul.suggestionList {
    list-style-type: none;
    text-align: left;
    font-family: Heebo;
    padding: 0;
    margin: 0;
}

.suggestionList li {
    cursor: pointer;
}

.suggestionList li:hover {
    background-color: #EFEFEF;
}

#markerIcon {
    font-size: small;
    color: grey;
}