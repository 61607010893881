@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;700&display=swap");

html {
  background: #ffffff;
  font-family: 'Heebo', sans-serif;
}

.MuiTypography-root {
  color: #009933;
  font-family: 'Heebo', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

#burguerMenu {
  color: #009933;
}

#logoutIcon {
  width: 18px !important;
  height: 18px !important;
  color: #767676;
}

#appBar {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(223, 223, 223, 0.25);
  display: flex;
  align-items: space-between;
}

.MuiListItemIcon-root {
  justify-content: center;
}

a {
  text-decoration: none;
}

.active .MuiButtonBase-root {
  background: rgba(255, 255, 255, 0.6);
}

.active .MuiButtonBase-root::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 4px;
  background: #009933;
}

.MuiButtonBase-root :nth-child(2) span {
  text-decoration: none;
}

#logo {
  position: absolute;
  width: 120px;
  height: 17.14px;
  top: 28px;
  left: 63px;
}

#outText {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #767676;
}

.MuiToolbar-root {
  display: flex;
  justify-content: space-between;
  margin: 0 40px
}

#signOut {
  display: flex;
  align-items: center;
}