.createEventMainDiv {
    margin-top: 64px;
    width: 100%;
}

.main {
    width: 100%;
    display: flex;
    margin-top: 70px;
}

.main .left-column {
    width: 15%;
    height: 100%;
}

.main .middle-column {
    width: 60%;
    height: 100%;
}

.main .right-column {
    width: 15%;
    height: 100%;
}

.main .middle-column .events-container {
    width: 100%;
    height: 600px;
    background-color: #EFEFEF;
    border-radius: 10px;
    overflow-y: scroll;
}

.main .middle-column .events-container .filters-bar {
    height: 100px;
    width: 100%;
    background-color: #383838;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 50px; 
    display: flex;
    align-items: center;
}

.main .middle-column .events-container .filters-bar form {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.main .middle-column .events-container .filters-bar form .filter label {
    color: white !important;
    font-weight: bold;
}

.date-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.date-container label {
    font-weight: bold;
    color: #009933;
}

.date-container .end-date-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 20px;
}

.date-container .start-date-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 20px;
}

.date-container input {
    height: 45px;
    padding: 5px 8px;
    border: 0 ;
    background-color: #EFEFEF;
    color: #636363;
    border-radius: 3px;
}

.date-container input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    width: 22px;
    height: 22px;
    filter: invert(1)
            brightness(50%)
            sepia(1000%)
            saturate(6000%)
            hue-rotate(90deg)
}

.date-container input:focus {
    outline: 2px solid #b3b3b3;
}

.main .middle-column .events-container .filters-bar form .filter {
    display: flex;
    flex-direction: column;
}

.main .middle-column .events-container .filters-bar form .filter select,.main .middle-column .events-container .filters-bar form .filter input {
    width: 150px;
    height: 30px;
    background-color: #EFEFEF;
    color: #383838;
    font-family: "Arial";
    border-radius: 5px;
    border: none;
    padding: 5px;
}

.events-container .events-display {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.events-container .events-display .event {
    display: flex;
    width: 85%;
    height: 100px;
    margin-top: 20px;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 0 50px;
}

.events-container .events-display .event:hover {
    background-color: #f7f7f7;
}

.event img {
    width: 40px;
}

.event .info p {
    margin: 0;
}

.event .details {
    display: flex;
    width: 25%;
}

.event .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
    font-size: 15px;
}

.event .info .competition {
    font-weight: bold;
    font-size: 12px;
}

.event .teams p {
    margin: 0;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.event .teams {
    margin: 20px 0;
    width: 55%;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 2px solid #E2E2E2;
}

.event .teams img {
    width: 25px;
    object-fit: cover;
    margin-right: 5px;
}

.event .teams .home-team-div, .event .teams .away-team-div {
    display: flex;
}

.event .start-time {
    width: 10%;
    border-right: 2px solid #E2E2E2;
    margin: 20px 0;
    padding: 0 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.event .start-time p {
    margin: 0;
    font-weight: bold;
    font-size: 15px;
}

.event .button-div {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.event .button-div .button {
    border-radius: 50%;
    background-color: #009933;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    cursor: pointer;
}

.event .button-div .button:hover {
    background-color: #383838;
    transition: all 0.2s ease;
}

.event .button-div .button p {
    color: white;
    font-size: 22px;
    margin: 0;
    cursor: pointer;
}

.event.active p {
    color: white;
}

.events-container .events-display .event.active {
    background-color: #383838;
}

.event.active .button-div .button {
    background-color: red;
}

.social-media-container {
    width: 100%;
    display: flex;
    margin-top: 30px;
}

.social-media-container .reddit-container {
    width: 50%;
    margin-right: 20px;
}

.social-media-container .youtube-container {
    width: 50%;
    margin-left: 20px;
}

.social-media-container span{
    color: red;
}

.social-media-container label {
    font-weight: bold;
}

.social-media-container .youtube-container input{
    background: url("../../assets/youtube-logo.svg") no-repeat right;
    background-size: 22px;
    height: 35px;
}

.social-media-container .reddit-container input{
    background: url("../../assets/reddit-logo.svg") no-repeat right;
    height: 35px;
}

.map-container {
    margin-top: 30px;
}

#eventMapLabel {
    font-weight: bold;
}

.submit-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 30px 0;
}

.submit-button {
    width: 150px;
    height: 45px;
    background-color: #009933;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
}

.submit-button:hover {
    background-color: #383838;
    transition: all 0.2s ease;
}

.events-display .noEventsDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}

.events-display .noEventsDiv h2{
    color: #383838;
    font-size: 20px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

.modal .header p {
    font-weight: bold;
    color: white;
    margin: 0;
}

.modal .header {
    background-color: #383838;
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    padding: 15px;
}

.modal .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 144px;
}

.modal .content p {
    color: #383838;
}

.modal .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding-bottom: 16px;
} 

.footer button {
    margin: 0 10px;
    border: none;
    width: 100px;
    height: 45px;
    font-weight: bold;
    border-radius: 5px;
    font-family: Roboto;
}

.footer button:hover {
    background-color: #383838;
    cursor: pointer;
}

.footer .cancel {
    background-color: rgb(161, 0, 0);
    color: white;
}

.footer .create {
    background-color: #009933;
    color: white;
}

.rodal-dialog {
    padding: 0 !important;
}

.rodal-close::before {
    background-color: white !important;
}

.rodal-close::after {
    background-color: white !important;
}

@mixin scrollbar($handleColor, $bkgColor) {
    overflow-y: auto;
    scrollbar-color: $handleColor $bkgColor;
    scrollbar-width: thin;
    
    /* width */
    .events-container::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    .events-container::-webkit-scrollbar-track {
        background-color: $bkgColor;
        border-radius: 8px;
    }

    /* Handle */
    .events-container::-webkit-scrollbar-thumb {
        background-color: $handleColor;
        border-radius: 8px;
    }

    /* Handle on hover */
    .events-container::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }
}