.demographics-container {
    width: 100%;
    height: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    margin: 64px 64px 0 64px;   
}

.top-row {
    width: 100%;
    display: flex;
}

.top-row .gender-breakdown-container {
    width: 50%;
    height: 400px;
    box-shadow: 0px 4px 6px 2px rgba(225, 225, 225, 0.5);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.top-row .age-group-container {
    width: 50%;
    margin-left: 25px;
    height: 400px;
    box-shadow: 0px 4px 6px 2px rgba(225, 225, 225, 0.5);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.demographics-container .main {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 25px;
}

.demographics-container .header {
    width: 100%;
    height: 50px;
    background-color: #353535;
    display: flex;
    align-items: center;
    padding-left: 25px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.demographics-container .header p {
    color: white;
    font-weight: bold;
    margin: 0;
    font-size: 14px;
}
