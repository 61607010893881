#actionCard {
  background: #ffffff;
  box-shadow: 0px 4px 6px 2px rgba(225, 225, 225, 0.5);
  border-radius: 10px;
  margin-top: 30px;
}

#actionCard h3 {
  margin: 0;
}

.cardTitleActions {
  width: 100%;
  height: 50px;
  background-color: #353535;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.cardTitleActions h2 {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: bold;
  font-family: Arial;
}

#actionCard h6 {
  margin: 0;
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #767676;
  margin-bottom: 10px;
  text-align: center;
}

#actionCard .cardContent {
  text-align: center;
  padding: 35px;
}

#actionCard .cardContent span .button-div :nth-child(1) {
  background: #008000;
  text-transform: unset;
  margin-bottom: 10px;
}

#actionCard .cardContent span .button-div :nth-child(1).end {
  background-color: #FF0000;
}

#actionCard .cardContent span .button-div :nth-child(1):hover {
  background-color: #353535;
}

#actionCard .cardContent span .button-div :nth-child(2):hover {
  background-color: #353535;
}
#actionCard .cardContent button {
  min-width: 145px;
  height: 32px;
  border-radius: 50px;
  font-weight: bold;
  color: #FFFFFF;
  border: 0;
  cursor: pointer;
}

.cardContent .button-div {
  display: flex;
  justify-content: center;
}

#actionCard .cardContent button:disabled {
  opacity: 60%;
}