@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;700&display=swap");

#mainGrid {
  position: absolute;
  top: 130px;
  margin: auto;
  padding: 0 10%;
}

* {
  font-family: "Heebo";
}

.mainBox {
  align-items: center;
}

h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.02em;
  margin: 0;
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
}

.drawerContainer .MuiDrawer-paper {
  right: 0;
  margin-top: 64px;
  margin-right: 0;
}

.tabs-container {
  margin-top: 64px;
  display: flex;
  justify-content: flex-end;
}

.tabs-container .tab {
  order: 1;
  position: fixed;
  width: 240px;
  background-color: #e5ffe9;
  height: 100%;
  z-index: 2;
}

.tabs-container .tab-panel {
  height: 100%;
  margin-right: 240px;
  width: 100%;
}

.tab button {
  display: flex;
  justify-content: flex-start;
  padding-left: 40px;
  font-size: 14px;
  text-transform: none;
  color: #009933;
  font-weight: 400;
  min-height: 48px !important;
}

.tab svg {
  width: 16px;
}

.tab button.Mui-selected {
  background-color: rgba(255, 255, 255, 0.6);
  color: #009933;
  border-left: 4px solid #009933;
}

.blank_page {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
}

.blank_page h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 22px;
  margin-left: 120px;
}