.topics-container {
    width: 100%;
    height: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    margin: 64px 64px 0 64px;
}

.topics-container .header {
    width: 100%;
    height: 50px;
    background-color: #353535;
    display: flex;
    align-items: center;
    padding-left: 25px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.topics-data {
    width: 66%;
    height: 400px;
    box-shadow: 0px 4px 6px 2px rgba(225, 225, 225, 0.5);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-right: 25px;
}

.topics-container .header p {
    color: white;
    font-weight: bold;
    margin: 0;
    font-size: 14px;
}

.filters {
    width: 34%;
    height: 400px;
    box-shadow: 0px 4px 6px 2px rgba(225, 225, 225, 0.5);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.topics-container .content {
    height: 100%;
    width: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.filters .checkboxes {
    display: flex;
}

.filters .middle {
    margin: 0 15px;
}

.filters label {
    color: #353535 !important;
}

.filters .content {
    flex-direction: column;
}

.filters .bottom-filters {
    margin-top: 30px;
}

.bottom-row {
    width: 100%;
}

.chart-title {
    width: 90%;
    font-size: 16px;
}

.topics-relevance-container {
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 6px 2px rgba(225, 225, 225, 0.5);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.chart {
    margin-bottom: 25px;
    width: 90%;
    height: 300px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#zoom-out {
    cursor: pointer;
    width: 100px;
    height: 40px;
    color: white;
    background-color: #353535;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    transition: all 0.2s ease;
}

#zoom-out:hover {
    background-color: #009933;
    transition: all 0.2s ease;
}

.header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 50px 80px 0 95px;
}