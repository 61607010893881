.createEventMainBox {
    align-items: center;
    position: absolute;
    top: 120px;
    padding: 0px 50px 0px 20px;
    margin: auto;
    text-align: center;
}

.createEventForm {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

/* FormLabel */
.MuiFormLabel-root {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #009933 !important;
    text-align: start;
}

/* autocomplete label */
label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #009933 !important;
    text-align: start;
}

input#txtEventName,
input#txtLocation,
input#txtCoords,
input#txtRange {
    width: 100%;
    height: 32px;
    background: #EFEFEF;
    border-radius: 4px;
    border: 0;
    color: #767676;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding: 5px;
}


/* input#countryAutocomplete {
    opacity: 0.7;
}

input#competitionAutocomplete {
    opacity: 0.7;
}

input#firstTeamAutocomplete {
    opacity: 0.7;
}

input#secondTeamAutocomplete {
    opacity: 0.7;
} */

input[type=text]:focus {
    outline: none;
    color: #767676;
}

input[type=text]:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #EFEFEF inset !important;
    box-shadow: 0 0 0px 1000px #EFEFEF inset !important;
    -webkit-text-fill-color: #767676;
}

input[type=datetime-local] {
    width: 100%;
    height: 32px;
    background: #EFEFEF;
    border-radius: 4px;
    border: 0;
    color: #767676;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding: 10px;
}

.react-tag-input {
    width: 100% !important;
    min-height: 32px !important;
    background: #EFEFEF !important;
    border-radius: 4px !important;
    border: 0 !important;
    padding: 5px !important;
}

.react-tag-input__input {
    color: #767676;
    font-style: normal;
    font-weight: 400;
    font-size: 13px !important;
    line-height: 14px;
}

.react-tag-input__tag {
    background: #D7D7D7!important;
}

.react-tag-input__tag__content {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.02em;
    color: black;
}

.react-tag-input__tag__remove {
    background: #C4C4C4 !important;
}

h6 {
    margin-top: 5px;
    margin-bottom: 0;
    text-align: start;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #767676;
}

.createEventSubmitBtn {
    background: #009933;
    color: #FFFFFF;
    width: 169px;
    height: 40px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    border: 0;
    text-align: center;
    margin: 0;
    margin-bottom: 40px;
    float: right;
    cursor: pointer;
}