.sentiments-container {
    width: 100%;
    height: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    margin: 64px 64px 0 64px;   
}

.top-row {
    width: 100%;
    display: flex;
}

.top-row .overall-sentiments-container {
    width: 34%;
    height: 400px;
    box-shadow: 0px 4px 6px 2px rgba(225, 225, 225, 0.5);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.top-row .chat-container {
    width: 66%;
    height: 400px;
    box-shadow: 0px 4px 6px 2px rgba(225, 225, 225, 0.5);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-left: 25px;
}

.sentiments-container .main {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 25px;
}

.chat-container .main {
    overflow-y: scroll;
}

.sentiments-container .header {
    width: 100%;
    height: 50px;
    background-color: #353535;
    display: flex;
    align-items: center;
    padding-left: 25px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.chart-header.sentiments {
    font-weight: bold;
}

.sentiments-container .header p {
    color: white;
    font-weight: bold;
    margin: 0;
    font-size: 14px;
}

.middle-row {
    width: 100%;
    height: 100%;
    margin-top: 25px;
    box-shadow: 0px 4px 6px 2px rgba(225, 225, 225, 0.5);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.middle-row .left-side, .middle-row .right-side {
    width: 50%;
}

.middle-row .right-side {
    margin-left: 25px;
}

.middle-row .left-side {
    margin-right: 25px;
}

.sentiments-container .chart {
    width: 100%;
    height: 325px;
}

.charts {
    width: 100%;
    height: 100%;
}

.chat-information-container {
    margin: 25px;
    display: flex;
}

.chart-header {
    margin: 0;
    margin-left: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
}

.chart-header p {
    overflow-x: clip;
    margin-bottom: 5px;
}

.chart-header.sentiments {
    margin-top: 60px;
}

.recharts-tooltip-wrapper {
    background-color: white !important;
    padding: 5px;
}

path {
    fill-opacity: 0.8;
}

.bottom-row {
    width: 100%;
    height: 100%;
    margin-top: 25px;
    box-shadow: 0px 4px 6px 2px rgba(225, 225, 225, 0.5);
}

.bottom-row .sentiments-analysis-container {
    display: flex;
} 

.bottom-row .left-side {
    width: 50%;
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: start;
    
}

.bottom-row .right-side {
    width: 50%;
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.bottom-row .checkboxes {
    margin: 25px 0;
}

.bottom-row .checkboxes label {
    color: #353535 !important;
    font-weight: bold;
    font-size: 14px;
}

.bottom-row .title p {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.bottom-row .title {
    width: 100%;
    border-bottom: 2px solid #E2E2E2;
}

#zoom-out {
    cursor: pointer;
    width: 100px;
    height: 40px;
    color: white;
    background-color: #353535;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    transition: all 0.2s ease;
}

#zoom-out:hover {
    background-color: #009933;
    transition: all 0.2s ease;
}



/* .bottom-row .checkboxes input:checked {
    background-color: #009933;
} */

@media (max-width: 1615px){
    .chat-information-container {
        flex-direction: column;
        align-items: center;
    }

    .middle-row .left-side {
        margin: 0;
    }

    .middle-row .right-side {
        margin: 0;
    }
}