.eventTableBox {
    align-items: center;
    position: absolute;
    top: 120px;
    padding: 0px 50px 0px 20px;
    margin: auto;
    text-align: center;
}

.MuiPaper-root {
    margin: auto;
}

.MuiTable-root .table {
    font-family: 'Heebo', sans-serif !important;

}

.tableHead th {
    background: #e5ffe9;
    color: #009933;
    font-weight: bold;
}

tbody td:nth-child(6),
tbody td:nth-child(7) {
    word-wrap: break-word
}

.event_table .rodal .header h3 {
    color: white;
}

.event_table .rodal .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow-y: scroll;
    height: 322px;
    padding: 35px;
}

.event_table .rodal .content .inputs {
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e2e2e2;
}

.event_table .rodal .reddit,
.event_table .rodal .youtube {
    display: flex;
    margin-top: 15px;
    width: 100%;
}

.event_table .rodal .content div label {
    font-weight: bold;
    color: #353535 !important;
}

.event_table .rodal .footer button {
    font-family: Arial, Helvetica, sans-serif;
}

.event_table .rodal .footer button:hover {
    background-color: #353535;
}

.event_table .rodal .footer .confirm {
    background-color: #009933;
    color: #FFFFFF;
}

.event_table .rodal-dialog {
    width: 500px !important;
    height: 450px !important;
}

.event_table .confirmation-modal .rodal-dialog {
    width: 400px !important;
    height: 200px !important;
}

.event_table .confirmation-modal .content {
    height: 75px;
}

.event_table .rodal .header {
    background-color: #383838;
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    padding: 15px;
}

.event_table .rodal .footer {
    height: 80px;
    width: 100%;
    padding-bottom: 0;
}

.event_table .confirmation-modal .footer {
    padding-top: 10px;
}

.event_table .confirmation-modal .content {
    align-items: center;
    overflow-y: hidden;
}

.event_table .table-container {
    max-width: 90%;
}

.event_table .confirmation-modal .footer button {
    background-color: #383838 !important;
    color: white !important;
}

.event_table .confirmation-modal .footer button:hover {
    background-color: #4b4b4b !important;
}

.event_table label {
    display: flex;
    align-items: center;
}

.event_table .react-tag-input {
    margin-left: 20px;
    overflow: scroll;
}

.MuiTableCell-root {
    max-width: 300px !important;
}