.infoTitle {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    font-family: Arial
  }
  
  #infoCard {
      position: relative;
      background: #ffffff;
      box-shadow: 0px 4px 6px 2px rgba(225, 225, 225, 0.5);
      border-radius: 10px;
    }
    
    /* #infoCard h3 {
      margin: 0;
      color: #009933;
      font-family: Arial;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      margin-top: 20px;
    }
    
    #infoCard h4 {
      margin: 0;
      font-family: Arial;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-top: 10px;
      color: #767676;
    }
    
    #infoCard .cardContent {
      margin-top: 20px;
      margin-bottom: 20px;
      
    } */

    #infoCard .header {
      width: 100%;
      height: 50px;
      background-color: #353535;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 25px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }

    #infoCard .header h3 {
      color: white;
      font-size: 14px;
    }

    #infoCard .content {
      padding: 50px 25px;
    }

    #infoCard .content .top-row {
      width: 100%;
    }

    #infoCard .content .top-row .event-id, #infoCard .content .top-row .event-sport-type, #infoCard .content .top-row .event-start-date, #infoCard .content .top-row .event-end-date {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    #infoCard .content .top-row h4 {
      margin: 0;
      color: #008000;
      font-size: 15px;
      font-weight: bold;
    }

    #infoCard .content .bottom-row {
      box-shadow: none;
      width: 100%;
    }

    #infoCard .content .top-row p {
      display: flex;
      align-items: center;
      margin: 0;
    }

    #infoCard .content .bottom-row .event-teams h4 {
      margin: 0;
      color: #008000;
      font-size: 15px;
      font-weight: bold;
    }

    #infoCard .content .bottom-row .event-teams p {
      display: flex;
      align-items: center;
      margin: 0;
      margin-top: 10px;
      font-size: 14px;
    }

    #infoCard .content .bottom-row .event-teams img {
      width: 30px;
    }

    #home-team-logo {
      margin-right: 8px;
    }

    #away-team-logo {
      margin-left: 8px;
    }
    
    .secondItem {
      align-self: flex-end;
      padding-bottom: 20px;
      padding-left: auto;
    }
    
    .infoBtn {
      background: #e4e4e4;
      border-radius: 50px;
      color: #353535;
      border: 0;
      min-width: 145px;
      height: 32px;
      font-family: Arial;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.02em;
      float: right;
      cursor: pointer;
    }

    .infoBtn:hover {
      background-color: #008000;
      color: white;
    }